import React from 'react';
import { Outlet, Link, useLocation } from "react-router-dom";
import { IonIcon } from '@ionic/react';
import { sunny, moon } from 'ionicons/icons';
import { useTheme } from '../context/ThemeContext';

const Navbar = () => {
  const location = useLocation();
  const { isDark, toggleTheme } = useTheme();
  
  const isActive = (linkPath) => {
    return location.pathname.includes(linkPath);
  };

  return (
    <div>
      <nav className="fixed 
        top-0 sm:top-0 md:top-0 lg:top-0 
        left-0 sm:left-0 md:left-0 lg:left-auto 
        w-[calc(100%-24px)] sm:w-[calc(100%-24px)] md:w-[calc(100%-24px)] lg:w-max 
        mx-3 sm:mx-3 md:mx-3 lg:mx-0
        lg:right-0
        bg-dark-eerie-black-2/80 dark:bg-light-eerie-black-2/80 backdrop-blur-md 
        rounded-lg z-50
        mt-2 sm:mt-2 md:mt-2 lg:mt-2 lg:mr-2 
        py-2 lg:py-3">
        <ul className="flex flex-wrap justify-center items-center px-2.5 lg:px-5 lg:gap-7">
          <li>
            <Link 
              className={`nav-link ${isActive('/about') ? 'active' : ''} lg:font-medium`}
              to="/about"
            >
              About
            </Link>
          </li>
          <li>
            <Link 
              className={`nav-link ${isActive('/resume') ? 'active' : ''} lg:font-medium`}
              to="/resume"
            >
              Resume
            </Link>
          </li>
          <li>
            <Link 
              className={`nav-link ${isActive('/portfolio') ? 'active' : ''} lg:font-medium`}
              to="/portfolio"
            >
              Portfolio
            </Link>
          </li>
          <li>
            <Link 
              className={`nav-link ${isActive('/contact') ? 'active' : ''} lg:font-medium`}
              to="/contact"
            >
              Contact
            </Link>
          </li>
          <li className="hidden lg:block">
            <button
              onClick={toggleTheme}
              className="relative bg-gradient-border-onyx w-[30px] h-[30px] rounded flex justify-center items-center text-base text-dark-blue-crayola dark:text-light-blue-crayola transition-colors duration-300 hover:text-dark-light-gray dark:hover:text-light-light-gray"
              aria-label="Toggle theme"
            >
              <IonIcon icon={isDark ? moon : sunny} />
            </button>
          </li>
        </ul>
      </nav>

      <div className="pt-16 sm:pt-16 md:pt-16 lg:pt-0">
        <Outlet />
      </div>
    </div>
  );
}

export default Navbar;
import bg_video from './assets/images/bg.gif';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import Sidebar from './components/Sidebar.js';
import Navbar from './components/Navbar.js';
import About from './components/About.js';
import Resume from './components/Resume.js';
import Portfolio from './components/Portfolio.js';
import Contact from './components/Contact.js';
import Project from './components/Project.js';
import NotFound from './components/NotFound.js';
import HelloWorld from './components/HelloWorld.js';

import data from './assets/content/content.json';
import { ThemeProvider } from './context/ThemeContext';

function App() {
  const PortfolioWithModal = () => {
    const location = useLocation();
    const modalId = location ? location.pathname.replace('/project/', '') : null;
    return <Project projectList={data.portfolio.projects} modalId={modalId} />;
  };
  
  return (
    <ThemeProvider>
      <div className="App">
        <div>
          <Toaster position="bottom-right"/>
        </div>

        <main className="mx-3 min-w-[259px] 
          sm:max-w-[640px] 
          md:max-w-[768px] 
          lg:max-w-[1024px] 
          xl:max-w-[1280px] 
          sm:mx-auto md:mx-auto lg:mx-auto xl:mx-auto
          flex flex-col md:flex-col lg:flex-row xl:flex-row 
          justify-center items-stretch gap-6 
          py-4 md:py-8 lg:py-[60px] xl:py-[60px]
          mb-0
          min-h-screen">

          <aside className="w-full md:w-full lg:w-1/4 xl:w-1/5 lg:sticky xl:sticky lg:top-[60px] xl:top-[60px]">
            <Sidebar />
          </aside>

          <div className="main-content relative 
            w-full md:w-full lg:w-3/4 xl:w-4/5 
            backdrop-blur-md
            lg:h-[calc(100vh-120px)] xl:h-[calc(100vh-120px)]">
            <div className="bg-dark-eerie-black-2 dark:bg-light-eerie-black-2 
              border border-[hsl(228,100%,72%)] rounded-lg 
              p-7 md:p-8 lg:p-[30px] xl:p-[30px] 
              lg:pt-[60px] xl:pt-[60px]
              shadow-custom-1
              lg:h-full xl:h-full
              lg:overflow-y-auto xl:overflow-y-auto
              overflow-visible">
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<Navbar />}>
                    <Route path="/" element={<Navigate to="about" replace={true} />} />
                    <Route path="about" element={<About />} /> 
                    <Route path="resume" element={<Resume resumeData={data.resume}/>} />
                    <Route path="portfolio" element={<Portfolio projectList={data.portfolio.projects} />} />
                    <Route path="contact" element={<Contact />} />
                    <Route path="project/*" element={<PortfolioWithModal />} />
                    <Route path="NotFound" element={<NotFound />} />
                    <Route path="helloworld" element={<HelloWorld />} />
                    <Route path="*" element={<Navigate to="NotFound" replace={true} />} />
                  </Route>
                </Routes>
              </BrowserRouter>
            </div>
          </div>
        </main>

        <footer className="fixed bottom-2 right-4 
          bg-dark-eerie-black-2/40 dark:bg-light-eerie-black-2/40 backdrop-blur-md
          text-dark-light-gray-70 dark:text-light-light-gray-70 
          text-fs-8 font-light
          px-2 py-1 rounded-lg
          border border-dark-jet/30 dark:border-light-jet/30">
          Made with 💙 by Ayman Ben hajjaj
        </footer>
      </div>
    </ThemeProvider>
  );
}

export default App;

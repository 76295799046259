import React, { useState } from 'react';
import { Navigate, Link } from 'react-router-dom';
import { IonIcon } from '@ionic/react';
import { chevronBack, close } from 'ionicons/icons';
import { createPortal } from 'react-dom';

// Import project images
import cover1 from '../assets/images/cover-1.jpg';
import cover2 from '../assets/images/cover-2.jpg';
import cover3 from '../assets/images/cover-3.jpg';
import cover4 from '../assets/images/cover-4.jpg';
import cover5 from '../assets/images/cover-5.jpg';
import cover6 from '../assets/images/cover-6.jpg';
import cover7 from '../assets/images/cover-7.jpg';

// Create a mapping object for project images
const imageMap = {
  'cover-1.jpg': cover1,
  'cover-2.jpg': cover2,
  'cover-3.jpg': cover3,
  'cover-4.jpg': cover4,
  'cover-5.jpg': cover5,
  'cover-6.jpg': cover6,
  'cover-7.jpg': cover7
};

const Project = ({ projectList, modalId }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  
  // Map the project images before finding the project
  const mappedProjects = projectList.map(project => ({
    ...project,
    pics: project.pics.map(pic => imageMap[pic])
  }));
  
  const project = mappedProjects.find(project => project.id === parseInt(modalId));
  if (!project) {
    return <Navigate to="/NotFound" />
  }

  const Modal = () => selectedImage && createPortal(
    <div 
      className="fixed inset-0 bg-black/50 flex justify-center items-center z-[9999] cursor-pointer p-5"
      onClick={() => setSelectedImage(null)}
    >
      <button 
        onClick={() => setSelectedImage(null)}
        className="absolute top-5 right-5 bg-transparent border-none text-white text-2xl cursor-pointer z-[10000]"
      >
        <IonIcon icon={close} />
      </button>
      <div 
        className="max-w-[90vw] max-h-[90vh] w-auto h-auto relative"
        onClick={e => e.stopPropagation()}
      >
        <img 
          src={selectedImage} 
          alt="Full size"
          className="w-full h-full object-contain rounded-lg shadow-lg max-w-[1200px] max-h-[800px]"
        />
      </div>
    </div>,
    document.body
  );

  return (
    <div className="projectPage p-[1px]">
      <header className="flex justify-between items-center mb-6">
        <Link 
          to="/Portfolio" 
          className="relative bg-gradient-border-onyx text-dark-blue-crayola dark:text-light-blue-crayola flex justify-center items-center gap-2.5 px-5 py-3 rounded-lg border border-dark-jet dark:border-light-jet shadow-custom-3 z-[1] transition-all duration-300 hover:bg-gradient-blue-1
            before:content-[''] before:absolute before:inset-[1px] before:bg-gradient-jet before:rounded-[inherit] before:-z-[1]"
        >
          <IonIcon icon={chevronBack} />
          Back
        </Link>
      </header>

      <h2 className="text-fs-1 text-dark-light-gray dark:text-light-light-gray mb-5">
        {project.title}
      </h2>
      
      {/* Tags */}
      <div className="flex flex-wrap gap-2.5 mb-5">
        {project.category.map((tag, index) => (
          <span 
            key={index} 
            className="bg-dark-eerie-black-1 dark:bg-light-eerie-black-1 px-4 py-1.5 rounded-full text-fs-6 text-dark-light-gray dark:text-light-light-gray border border-dark-jet dark:border-light-jet"
          >
            {tag}
          </span>
        ))}
      </div>

      {/* Image Carousel */}
      <section className="relative my-5 p-4 bg-gradient-border-onyx rounded-xl shadow-custom-2 z-[1]
        before:content-[''] before:absolute before:inset-[1px] before:bg-gradient-jet before:rounded-xl before:-z-[1]">
        <div className="image-carousel flex gap-4 overflow-x-auto p-0 h-[200px] snap-x snap-mandatory scroll-smooth">
          {project.pics.map((image, index) => (
            <div 
              key={index} 
              onClick={() => setSelectedImage(image)}
              className="flex-shrink-0 snap-start h-full aspect-video rounded-2xl overflow-hidden cursor-pointer"
            >
              <img 
                src={image} 
                alt={`${project.title} illustration ${index + 1}`}
                className="w-full h-full object-cover"
              />
            </div>
          ))}
        </div>
      </section>

      {/* Content Section */}
      <div className="flex flex-col gap-8 mt-8">
        {/* Top Section - Summary and Links */}
        <section className="relative p-4 bg-gradient-border-onyx rounded-xl shadow-custom-2 z-[1]
          before:content-[''] before:absolute before:inset-[1px] before:bg-gradient-jet before:rounded-xl before:-z-[1]">
          <div className="flex flex-col md:flex-row gap-8">
            {/* Summary */}
            <div className="flex-[2]">
              <h4 className="text-dark-light-gray dark:text-light-light-gray text-fs-4 font-medium mb-2">Summary</h4>
              <p className="text-dark-light-gray dark:text-light-light-gray text-left mb-5 md:mb-0">
                {project.summary}
              </p>
            </div>

            {/* Links */}
            <div className="flex-1">
              <h4 className="text-dark-light-gray dark:text-light-light-gray text-fs-4 font-medium mb-2">Links</h4>
              <div className="flex flex-wrap gap-2.5">
                {project.links.map((link, index) => (
                  <a 
                    key={index}
                    href={link.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="relative bg-gradient-border-onyx text-dark-blue-crayola dark:text-light-blue-crayola px-2 py-1.5 rounded-xl border border-dark-blue-sky-2 dark:border-light-blue-sky-2 shadow-custom-3 z-[1] transition-all duration-300 hover:bg-gradient-blue-1 text-fs-6
                      before:content-[''] before:absolute before:inset-[1px] before:bg-gradient-jet before:rounded-[inherit] before:-z-[1]"
                  >
                    {link.title}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </section>

        {/* Bottom Section - Description and Tools */}
        <section className="relative p-4 bg-gradient-border-onyx rounded-xl shadow-custom-2 z-[1]
          before:content-[''] before:absolute before:inset-[1px] before:bg-gradient-jet before:rounded-xl before:-z-[1]">
          <div className="flex flex-col md:flex-row gap-8">
            {/* Description */}
            <div className="flex-[2]">
              <h4 className="text-dark-light-gray dark:text-light-light-gray text-fs-4 font-medium mb-2">Description</h4>
              <p className="text-dark-light-gray dark:text-light-light-gray text-left whitespace-pre-line">
                {project.description}
              </p>
            </div>

            {/* Tools */}
            <div className="flex-1">
              <h4 className="text-dark-light-gray dark:text-light-light-gray text-fs-4 font-medium mb-2">Tools & Technologies</h4>
              <div className="flex flex-wrap gap-2">
                {project.tools.map((tool, index) => (
                  <span 
                    key={index}
                    className="bg-dark-eerie-black-1 dark:bg-light-eerie-black-1 px-3 py-1 rounded-full text-fs-7 text-dark-light-gray dark:text-light-light-gray border border-dark-jet dark:border-light-jet"
                  >
                    {tool}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* Modal */}
      <Modal />
    </div>
  );
};

export default Project;

import React from 'react';
import { IonIcon } from '@ionic/react';
import { business, school } from 'ionicons/icons';

const ResumeComponent = ({ componentList, sectionTitle }) => {
  return (
    <div>
      <section className="mb-8">
        <div className="flex items-center gap-4 mb-6">
          <div className="relative bg-gradient-border-onyx w-12 h-12 rounded-xl flex justify-center items-center text-lg text-dark-blue-crayola dark:text-light-blue-crayola shadow-custom-1 border border-light-jet dark:border-dark-jet">
            <IonIcon icon={sectionTitle === 'Education' ? school : business} />
          </div>
          <h3 className="text-fs-2 text-dark-light-gray dark:text-light-light-gray">
            {sectionTitle}
          </h3>
        </div>

        <ol className="ml-[45px] text-fs-6">
          {componentList.map((component, index) => (
            <li key={index} className="relative mb-5 last:mb-0">
              <div className="flex items-center gap-3 mb-2">
                {component.logo && (
                  <img 
                    src={component.logo} 
                    alt={component.activityOrganisation} 
                    className="w-8 h-8 md:w-10 md:h-10 object-contain rounded"
                  />
                )}
                <div>
                  <h4 className="text-fs-2 text-dark-light-gray dark:text-light-light-gray leading-tight font-bold">
                    {component.activityTitle}
                  </h4>
                  <span className="text-dark-blue-sky-1 dark:text-light-blue-sky-1 leading-6 text-fs-3 font-semibold">
                    {component.activityOrganisation}
                  </span>
                </div>
              </div>
              
              <span className="block text-dark-blue-sky-2 dark:text-light-blue-sky-2 text-fs-4 font-semibold leading-6">
                {component.activityTimeFrame}
              </span>
              <p className="text-dark-light-gray dark:text-light-light-gray font-light leading-6 text-justify">
                {component.activityDescription}
              </p>

              {/* Timeline decorations */}
              {index !== componentList.length - 1 && (
                <div className="absolute top-[-25px] left-[-22px] w-[1px] h-[calc(100%+50px)] bg-light-jet dark:bg-dark-jet" />
              )}
              <div className="absolute top-[5px] left-[-25px] h-[6px] w-[6px] bg-text-gradient-yellow rounded-full shadow-[0_0_0_4px_var(--tw-shadow-color)] shadow-light-jet dark:shadow-dark-jet" />
            </li>
          ))}
        </ol>
      </section>
    </div>
  );
};

export default ResumeComponent;

import React from 'react';
import client_1 from '../assets/images/logo-1-color.png';
import client_2 from '../assets/images/logo-2-color.png';
import client_3 from '../assets/images/logo-3-color.png';
import client_4 from '../assets/images/logo-4-color.png';
import client_5 from '../assets/images/logo-5-color.png';

import skill_1 from '../assets/images/icon-1.svg';
import skill_2 from '../assets/images/icon-2.svg';
import skill_3 from '../assets/images/icon-3.svg';
import skill_4 from '../assets/images/icon-4.svg';

import { IonIcon } from '@ionic/react';
import { documentTextOutline, folderOpenOutline } from 'ionicons/icons';
import { Link } from 'react-router-dom';

const About = () => {
  return (
    <div>
      <header className="mb-4">
        <h2 className="text-fs-1 text-dark-light-gray dark:text-light-light-gray capitalize relative pb-2 after:content-[''] after:absolute after:bottom-0 after:left-0 after:w-[30px] after:h-[3px] after:bg-text-gradient-yellow after:rounded">
          About me
        </h2>
      </header>

      <section className="mb-9">
        <p className="text-dark-light-gray dark:text-light-light-gray text-fs-6 font-light leading-6">
          <b>5th-year Engineering</b> student at <b>EFREI Paris school</b>, I'm eager to secure my first full time <b>Entry-level</b> position in <b>Data Science</b> or Data Engineering.
          <br/>
          With expertise in <b>DevOps</b> tools and experience in <b>MLOps</b>, I'm particularly interested in <b>ML Engineering</b> roles.
        </p>
      </section>

      <section className="mb-9">
        <div className="flex justify-between items-center mb-5">
          <h3 className="text-fs-2 text-dark-light-gray dark:text-light-light-gray">
            They Trust Me
          </h3>

          <Link 
            to="/resume"
            className="relative bg-gradient-border-onyx text-dark-blue-crayola dark:text-light-blue-crayola flex justify-center items-center gap-2 px-4 py-2 rounded-lg text-fs-6 capitalize shadow-custom-3 z-[1] transition-all duration-300 hover:bg-gradient-blue-1
              before:content-[''] before:absolute before:inset-[1px] before:bg-gradient-jet before:rounded-[inherit] before:-z-[1]"
          >
            <IonIcon icon={documentTextOutline} className="text-base" />
            <span>My Resume</span>
          </Link>
        </div>

        <ul className="flex justify-start items-start gap-4 mx-[-15px] px-4 overflow-x-auto scroll-smooth overscroll-x-contain snap-x snap-mandatory scroll-pl-[45px] [&::-webkit-scrollbar]:h-1.5
          md:gap-[50px] md:mx-12 md:scroll-pl-[45px]
          [&::-webkit-scrollbar-track]:bg-dark-jet/20
          [&::-webkit-scrollbar-thumb]:bg-dark-jet/40
          dark:[&::-webkit-scrollbar-track]:bg-light-jet/20
          dark:[&::-webkit-scrollbar-thumb]:bg-light-jet/40">
          {[client_1, client_2, client_3, client_4, client_5].map((client, index) => (
            <li key={index} className="min-w-[50%] snap-start md:min-w-[calc(33.33%-35px)]">
              <a href="/resume">
                <img 
                  src={client} 
                  alt="client logo" 
                  className="w-full grayscale hover:grayscale-0 transition-all duration-300"
                />
              </a>
            </li>
          ))}
        </ul>
      </section>

      <section className="mb-9">
        <div className="flex justify-between items-center mb-5">
          <h3 className="text-fs-2 text-dark-light-gray dark:text-light-light-gray">
            What i'm doing
          </h3>

          <Link 
            to="/portfolio"
            className="relative bg-gradient-border-onyx text-dark-blue-crayola dark:text-light-blue-crayola flex justify-center items-center gap-2 px-4 py-2 rounded-lg text-fs-6 capitalize shadow-custom-3 z-[1] transition-all duration-300 hover:bg-gradient-blue-1
              before:content-[''] before:absolute before:inset-[1px] before:bg-gradient-jet before:rounded-[inherit] before:-z-[1]"
          >
            <IonIcon icon={folderOpenOutline} className="text-base" />
            <span>My Projects</span>
          </Link>
        </div>

        <ul className="grid grid-cols-1 gap-5 md:grid-cols-2">
          {/* Service Item 1 - Deployment Orchestration */}
          <li className="relative bg-gradient-border-onyx p-5 rounded-lg shadow-custom-2 z-[1] 
            before:content-[''] before:absolute before:inset-[1px] before:bg-gradient-jet before:rounded-lg before:-z-[1]
            md:flex md:justify-start md:items-start md:gap-[18px]">
            <div className="mb-2.5 md:mb-0 md:mt-[5px] md:min-w-[60px] lg:min-w-[60px]">
              <img src={skill_2} alt="Web development icon" className="w-10 md:w-[60px] lg:w-[60px] xl:w-[60px] mx-auto" />
            </div>

            <div className="text-center md:text-left">
              <h4 className="text-fs-4 text-dark-light-gray dark:text-light-light-gray mb-2">
                Deployment Orchestration and MLOps Management
              </h4>
              <p className="text-dark-light-gray dark:text-light-light-gray text-fs-6 font-light leading-6">
                Expertise in deploying machine learning models efficiently in production environments, managing CI/CD pipelines, and ensuring model performance through MLOps practices.
              </p>
            </div>
          </li>

          {/* Service Item 2 - Pipeline Development */}
          <li className="relative bg-gradient-border-onyx p-5 rounded-lg shadow-custom-2 z-[1] 
            before:content-[''] before:absolute before:inset-[1px] before:bg-gradient-jet before:rounded-lg before:-z-[1]
            md:flex md:justify-start md:items-start md:gap-[18px]">
            <div className="mb-2.5 md:mb-0 md:mt-[5px] md:min-w-[60px] lg:min-w-[60px]">
              <img src={skill_3} alt="mobile app icon" className="w-10 md:w-[60px] lg:w-[60px] xl:w-[60px] mx-auto" />
            </div>

            <div className="text-center md:text-left">
              <h4 className="text-fs-4 text-dark-light-gray dark:text-light-light-gray mb-2">
                Pipeline Development and Database Management
              </h4>
              <p className="text-dark-light-gray dark:text-light-light-gray text-fs-6 font-light leading-6">
                Ability to transform data into visually appealing insights and reports, facilitating informed decision-making and strategy development for businesses.
              </p>
            </div>
          </li>

          {/* Service Item 3 - Data Visualization */}
          <li className="relative bg-gradient-border-onyx p-5 rounded-lg shadow-custom-2 z-[1] 
            before:content-[''] before:absolute before:inset-[1px] before:bg-gradient-jet before:rounded-lg before:-z-[1]
            md:flex md:justify-start md:items-start md:gap-[18px]">
            <div className="mb-2.5 md:mb-0 md:mt-[5px] md:min-w-[60px] lg:min-w-[60px]">
              <img src={skill_4} alt="camera icon" className="w-10 md:w-[60px] lg:w-[60px] xl:w-[60px] mx-auto" />
            </div>

            <div className="text-center md:text-left">
              <h4 className="text-fs-4 text-dark-light-gray dark:text-light-light-gray mb-2">
                Data Visualization, Reporting, and Business Insights
              </h4>
              <p className="text-dark-light-gray dark:text-light-light-gray text-fs-6 font-light leading-6">
                Skills in designing and maintaining data processing pipelines, along with managing databases for efficient storage, retrieval, and management of data assets.
              </p>
            </div>
          </li>

          {/* Service Item 4 - Advanced Analytics */}
          <li className="relative bg-gradient-border-onyx p-5 rounded-lg shadow-custom-2 z-[1] 
            before:content-[''] before:absolute before:inset-[1px] before:bg-gradient-jet before:rounded-lg before:-z-[1]
            md:flex md:justify-start md:items-start md:gap-[18px]">
            <div className="mb-2.5 md:mb-0 md:mt-[5px] md:min-w-[60px] lg:min-w-[60px]">
              <img src={skill_1} alt="design icon" className="w-10 md:w-[60px] lg:w-[60px] xl:w-[60px] mx-auto" />
            </div>

            <div className="text-center md:text-left">
              <h4 className="text-fs-4 text-dark-light-gray dark:text-light-light-gray mb-2">
                Advanced Analytics and Experimentation
              </h4>
              <p className="text-dark-light-gray dark:text-light-light-gray text-fs-6 font-light leading-6">
                Machine learning, deep learning, and statistical analysis to develop models and conduct experiments for data-driven insights and predictions.
              </p>
            </div>
          </li>
        </ul>
      </section>
    </div>
  );
}

export default About;
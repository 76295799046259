import React, { useState } from 'react';
import { IonIcon } from '@ionic/react';
import { documentTextOutline, mailOutline, close } from 'ionicons/icons';
import { createPortal } from 'react-dom';
import emailjs from '@emailjs/browser';
import toast from 'react-hot-toast';

// Import the logo images
import bnpLogo from '../assets/images/resume-logo-bnp.png';
import hpeLogo from '../assets/images/resume-logo-hpe.png';
import efreiLogo from '../assets/images/resume-logo-efrei.jpg';
import pythagoreLogo from '../assets/images/resume-logo-pythagore.jpg';

import ResumeComponent from './ResumeComponent';

// Create a mapping object for the logos
const logoMap = {
  'resume-logo-bnp.png': bnpLogo,
  'resume-logo-hpe.png': hpeLogo,
  'resume-logo-efrei.jpg': efreiLogo,
  'resume-logo-pythagore.jpg': pythagoreLogo
};

const Resume = ({ resumeData }) => {
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState('');
  const [isSending, setIsSending] = useState(false);
  
  // Map the logo paths to the imported images
  const mappedResumeData = {
    ...resumeData,
    experienceList: {
      ...resumeData.experienceList,
      list: resumeData.experienceList.list.map(item => ({
        ...item,
        logo: logoMap[item.logo.split('/').pop()]
      }))
    },
    educationList: {
      ...resumeData.educationList,
      list: resumeData.educationList.list.map(item => ({
        ...item,
        logo: logoMap[item.logo.split('/').pop()]
      }))
    }
  };

  const handleSendEmail = async () => {
    if (!email) return;
    
    setIsSending(true);
    try {
      await emailjs.send(
        'service_ifcbqim',
        'template_rd6utek',
        { from_email: email },
        'J-npdjAzuoOGtgBaj'
      );
      
      toast.success("Resume sent successfully!", {
        style: {
          border: '3px solid #BDD358',
          padding: '16px',
          color: '#FFFAEE',
          backgroundColor: '#00001a',
        },
        iconTheme: {
          secondary: '#FFFAEE',
          primary: '#BDD358',
        },
      });
      
      setShowModal(false);
      setEmail('');
    } catch (error) {
      toast.error("Failed to send resume. Please try again.", {
        style: {
          border: '3px solid #E5625E',
          padding: '16px',
          color: '#FFFAEE',
          backgroundColor: '#00001a',
        },
        iconTheme: {
          secondary: '#FFFAEE',
          primary: '#E5625E',
        },
      });
    } finally {
      setIsSending(false);
    }
  };

  const Modal = () => showModal && createPortal(
    <div 
      className="fixed inset-0 bg-black/50 flex items-center justify-center z-[9999] p-4"
      onClick={() => setShowModal(false)}
    >
      <div 
        className="relative bg-dark-eerie-black-2/80 dark:bg-light-eerie-black-2/80 backdrop-blur-md rounded-lg p-6 max-w-md w-full border border-dark-jet/30 dark:border-light-jet/30"
        onClick={e => e.stopPropagation()}
      >
        <button 
          onClick={() => setShowModal(false)}
          className="absolute top-4 right-4 text-dark-light-gray dark:text-light-light-gray hover:text-dark-light-gray-70 dark:hover:text-light-light-gray-70 transition-colors duration-300"
          aria-label="Close modal"
        >
          <IonIcon icon={close} className="text-xl" />
        </button>

        <h3 className="text-dark-light-gray dark:text-light-light-gray text-fs-3 font-medium mb-4">
          Receive Resume by Email
        </h3>

        <input 
          type="email" 
          placeholder="Enter your email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full bg-transparent border border-dark-jet dark:border-light-jet rounded-lg px-4 py-2 mb-4 text-dark-light-gray dark:text-light-light-gray text-fs-6 outline-none focus:border-dark-blue-crayola dark:focus:border-light-blue-crayola"
        />

        <div className="flex justify-end">
          <button 
            onClick={handleSendEmail}
            disabled={!email || isSending}
            className="relative bg-gradient-border-onyx text-dark-blue-crayola dark:text-light-blue-crayola flex justify-center items-center gap-2 px-4 py-2 rounded-lg text-fs-6 capitalize shadow-custom-3 z-[1] transition-all duration-500 hover:bg-gradient-blue-1 disabled:opacity-50 disabled:cursor-not-allowed
              before:content-[''] before:absolute before:inset-[1px] before:bg-gradient-jet before:rounded-[inherit] before:-z-[1] before:transition-all before:duration-500 hover:before:bg-gradient-blue-2"
          >
            {isSending ? 'Sending...' : 'Send'}
          </button>
        </div>
      </div>
    </div>,
    document.body
  );

  return (
    <div>
      <header className="mb-8">
        <h2 className="text-fs-1 text-dark-light-gray dark:text-light-light-gray capitalize relative pb-2 after:content-[''] after:absolute after:bottom-0 after:left-0 after:w-[40px] after:h-[5px] after:bg-text-gradient-yellow after:rounded mb-4">
          Resume
        </h2>

        <div className="flex flex-col sm:flex-row items-center gap-2 sm:gap-4">
          <a 
            href={mappedResumeData.pdfLink} 
            target="_blank" 
            rel="noreferrer" 
            className="relative bg-gradient-border-onyx text-dark-blue-crayola dark:text-light-blue-crayola flex justify-center items-center gap-2 px-4 py-2 rounded-lg text-fs-6 capitalize shadow-custom-3 z-[1] transition-all duration-500 hover:bg-gradient-blue-1 w-full sm:w-auto
              before:content-[''] before:absolute before:inset-[1px] before:bg-gradient-jet before:rounded-[inherit] before:-z-[1] before:transition-all before:duration-500 hover:before:bg-gradient-blue-2"
          >
            <IonIcon icon={documentTextOutline} className="text-base" />
            <span>View PDF Version</span>
          </a>

          <div className="divider divider-horizontal text-dark-light-gray dark:text-light-light-gray hidden sm:flex">OR</div>
          <div className="divider text-dark-light-gray dark:text-light-light-gray sm:hidden">OR</div>

          <button 
            onClick={() => setShowModal(true)}
            className="relative bg-gradient-border-onyx text-dark-blue-crayola dark:text-light-blue-crayola flex justify-center items-center gap-2 px-4 py-2 rounded-lg text-fs-6 capitalize shadow-custom-3 z-[1] transition-all duration-500 hover:bg-gradient-blue-1 w-full sm:w-auto
              before:content-[''] before:absolute before:inset-[1px] before:bg-gradient-jet before:rounded-[inherit] before:-z-[1] before:transition-all before:duration-500 hover:before:bg-gradient-blue-2"
          >
            <IonIcon icon={mailOutline} className="text-base" />
            <span>Receive it by Email</span>
          </button>
        </div>
      </header>

      <ResumeComponent componentList={mappedResumeData.experienceList.list} sectionTitle={mappedResumeData.experienceList.sectionTitle} />
      <ResumeComponent componentList={mappedResumeData.educationList.list} sectionTitle={mappedResumeData.educationList.sectionTitle} />

      <Modal />
    </div>
  );
};

export default Resume;

import React, { useState } from 'react';
import avatar from '../assets/images/my-avatar.png';
import { IonIcon } from '@ionic/react';
import { 
  chevronDown, 
  mailOutline, 
  phonePortraitOutline, 
  locationOutline, 
  calendarOutline, 
  logoGithub, 
  logoLinkedin,
  sunny,
  moon
} from 'ionicons/icons';
import { useTheme } from '../context/ThemeContext';

const Sidebar = () => {
  const [showContacts, setShowContacts] = useState(false);
  const { isDark, toggleTheme } = useTheme();

  return (
    <div className={`sidebar-container max-h-28 overflow-hidden transition-all duration-500 
      ${showContacts ? 'max-h-[405px]' : 'md:max-h-[150px]'} 
       md:h-auto lg:sticky lg:top-[60px] lg:max-h-max lg:h-full lg:mb-0 lg:pt-[60px] lg:z-[1]`}>
      {/* Info Section */}
      <div className="relative flex justify-start items-center gap-4 
        lg:flex-col lg:items-center">
        {/* Avatar */}
        <figure className="bg-gradient-onyx rounded-lg">
          <img 
            src={avatar} 
            alt="Ayman Ben hajjaj" 
            className="w-20 h-20 rounded-lg md:w-[120px] md:h-[120px] lg:w-[150px] lg:h-[150px]" 
          />
        </figure>

        {/* Info Content */}
        <div className="lg:text-center">
          <h1 className="text-dark-light-gray dark:text-light-light-gray text-fs-3 font-medium -tracking-[0.25px] mb-2.5 lg:whitespace-nowrap">
            Ayman Ben hajjaj
          </h1>
          <p className="text-dark-light-gray text-fs-3 font-light px-3 py-0.5 rounded inline-block lg:mx-auto bg-dark-onyx">
            Data - AI Engineer
          </p>
        </div>

        {/* Control Buttons Container */}
        <div className="absolute -top-3 -right-4 flex gap-1.5 lg:hidden">
          {/* Theme Toggle Button */}
          <button
            onClick={toggleTheme}
            className="rounded-lg w-9 h-9 text-sm text-dark-blue-crayola dark:text-light-blue-crayola bg-gradient-border-onyx border border-[hsl(228,100%,72%)] transition-colors duration-300 z-10 flex items-center justify-center"
            aria-label="Toggle theme"
          >
            <IonIcon icon={isDark ? moon : sunny} className="text-lg" />
          </button>

          {/* Show Contacts Button */}
          <button 
            onClick={() => setShowContacts(!showContacts)}
            className="rounded-lg h-9 mr-1 text-sm text-dark-blue-crayola dark:text-light-blue-crayola bg-gradient-border-onyx border border-[hsl(228,100%,72%)] transition-colors duration-300 z-10 flex items-center justify-center"
          >
            <span className="hidden md:block px-2">Show Contacts</span>
            <IonIcon icon={chevronDown} className="text-lg md:hidden w-9" />
          </button>
        </div>
      </div>

      {/* Contact Information */}
      <div className={`transition-all duration-500 ${showContacts ? 'opacity-100 visible' : 'opacity-0 invisible'} 
        md:opacity-100 md:visible lg:opacity-100 lg:visible`}>
        {/* Separator */}
        <div className="w-full h-px bg-light-jet dark:bg-dark-jet my-4 lg:my-8" />

        {/* Contacts List */}
        <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-4 md:gap-[30px_15px] lg:gap-5">
          {/* Email */}
          <li className="flex items-center gap-4">
            <div className="relative bg-gradient-border-onyx w-[30px] h-[30px] rounded flex justify-center items-center text-base text-dark-blue-crayola dark:text-light-blue-crayola shadow-custom-1">
              <IonIcon icon={mailOutline} />
            </div>
            <div className="min-w-0 flex-1">
              <p className="text-dark-light-gray-70 dark:text-light-light-gray-70 text-fs-8 uppercase mb-0.5">Email</p>
              <a href="mailto:benhajjaj.ayman@gmail.com" 
                className="text-dark-light-gray dark:text-light-light-gray text-fs-7 lg:text-[14px] lg:font-light underline block truncate"
                title="benhajjaj.ayman@gmail.com"
              >
                benhajjaj.ayman@gmail.com
              </a>
            </div>
          </li>

          {/* Phone */}
          <li className="flex items-center gap-4">
            <div className="relative bg-gradient-border-onyx w-[30px] h-[30px] rounded flex justify-center items-center text-base text-dark-blue-crayola dark:text-light-blue-crayola shadow-custom-1">
              <IonIcon icon={phonePortraitOutline} />
            </div>
            <div className="max-w-[calc(100%-46px)]">
              <p className="text-dark-light-gray-70 dark:text-light-light-gray-70 text-fs-8 uppercase mb-0.5">Phone</p>
              <a href="tel:+33662589502" className="text-dark-light-gray dark:text-light-light-gray text-fs-7 lg:text-[14px] lg:font-light">
                +33 662 589 502
              </a>
            </div>
          </li>

          {/* Calendar */}
          <li className="flex items-center gap-4">
            <div className="relative bg-gradient-border-onyx w-[30px] h-[30px] rounded flex justify-center items-center text-base text-dark-blue-crayola dark:text-light-blue-crayola shadow-custom-1">
              <IonIcon icon={calendarOutline} />
            </div>
            <div className="max-w-[calc(100%-46px)]">
              <p className="text-dark-light-gray-70 dark:text-light-light-gray-70 text-fs-8 uppercase mb-0.5">Book a meeting</p>
              <a href="https://calendly.com/benhajjaj-ayman/20min" target="_blank" rel="noreferrer" className="text-dark-light-gray dark:text-light-light-gray text-fs-7 lg:text-[14px] lg:font-light">
                Calendly
              </a>
            </div>
          </li>

          {/* Location */}
          <li className="flex items-center gap-4">
            <div className="relative bg-gradient-border-onyx w-[30px] h-[30px] rounded flex justify-center items-center text-base text-dark-blue-crayola dark:text-light-blue-crayola shadow-custom-1">
              <IonIcon icon={locationOutline} />
            </div>
            <div className="max-w-[calc(100%-46px)]">
              <p className="text-dark-light-gray-70 dark:text-light-light-gray-70 text-fs-8 uppercase mb-0.5">Location</p>
              <address className="text-dark-light-gray dark:text-light-light-gray text-fs-7 lg:text-[14px] lg:font-light not-italic">
                Paris, Île-de-France, France
              </address>
            </div>
          </li>
        </ul>

        {/* Separator */}
        <div className="w-full h-px bg-light-jet dark:bg-dark-jet my-4 lg:my-[15px] lg:opacity-0" />

        {/* Social Links */}
        <ul className="flex justify-start lg:justify-center items-center gap-4 pb-1 pl-2">
          <li>
            <a 
              href="https://www.linkedin.com/in/benhajjaj/" 
              target="_blank" 
              rel="noreferrer"
              className="text-dark-light-gray-70 dark:text-light-light-gray-70 text-lg hover:text-dark-light-gray dark:hover:text-light-light-gray transition-colors duration-300"
            >
              <IonIcon icon={logoLinkedin} />
            </a>
          </li>
          <li>
            <a 
              href="https://github.com/Senshiben-efrei/" 
              target="_blank" 
              rel="noreferrer"
              className="text-dark-light-gray-70 dark:text-light-light-gray-70 text-lg hover:text-dark-light-gray dark:hover:text-light-light-gray transition-colors duration-300"
            >
              <IonIcon icon={logoGithub} />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Sidebar;
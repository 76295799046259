import React from 'react';

class HelloWorld extends React.Component {
    render() {
        return (
            <div >
                <header>
                <h2 className="h2 article-title">Hello World</h2>
                </header>
            </div>
        );
    }
}

export default HelloWorld;
import React, { useRef } from 'react';
import { IonIcon } from '@ionic/react';
import { paperPlane } from 'ionicons/icons';
import emailjs from '@emailjs/browser';
import { Toaster, toast } from 'react-hot-toast';

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_zl7bssm', 'template_36s94wu', form.current, {
        publicKey: 'qR4CK0idra5ezhgav',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          // clear form
          form.current.reset();
          // show success message
          toast.success("Message sent successfully !", {
            style: {
              border: '3px solid #BDD358',
              padding: '16px',
              color: '#FFFAEE',
              backgroundColor: '#00001a',
            },
            iconTheme: {
              secondary: '#FFFAEE',
              primary: '#BDD358',
            },
          });
        },
        (error) => {
          console.log('FAILED...', error.text);
          // show error message
          toast.error("Message not sent ! \nTry an other contact method", {
            style: {
              border: '3px solid #E5625E',
              padding: '16px',
              color: '#FFFAEE',
              backgroundColor: '#00001a',
            },
            iconTheme: {
              secondary: '#FFFAEE',
              primary: '#E5625E',
            },
          });
        },
      );
  };

  return (
    <div>
      <header className="mb-4">
        <h2 className="text-fs-1 text-dark-light-gray dark:text-light-light-gray capitalize relative pb-2 after:content-[''] after:absolute after:bottom-0 after:left-0 after:w-[40px] after:h-[5px] after:bg-text-gradient-yellow after:rounded">
          Contact
        </h2>
      </header>

      <p className="text-dark-light-gray dark:text-light-light-gray text-fs-6 mb-4">
        You can book a meeting on Calendly or contact me directly on my Email address, Phone number or by filling the form below.
      </p>

      <div className="w-full h-px bg-light-jet dark:bg-dark-jet my-4" />

      <section>
        <h3 className="text-fs-2 text-dark-light-gray dark:text-light-light-gray mb-5">
          Contact Form
        </h3>

        <form ref={form} onSubmit={sendEmail} className="mb-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <input 
              type="text" 
              name="fullname" 
              placeholder="Full name" 
              required
              className="text-dark-light-gray dark:text-light-light-gray text-fs-6 font-normal px-5 py-3 border border-light-jet dark:border-dark-jet rounded-lg bg-transparent outline-none focus:border-dark-blue-crayola dark:focus:border-light-blue-crayola"
            />

            <input 
              type="email" 
              name="email" 
              placeholder="Email address" 
              required
              className="text-dark-light-gray dark:text-light-light-gray text-fs-6 font-normal px-5 py-3 border border-light-jet dark:border-dark-jet rounded-lg bg-transparent outline-none focus:border-dark-blue-crayola dark:focus:border-light-blue-crayola"
            />
          </div>

          <textarea 
            name="message" 
            placeholder="Your Message" 
            required
            className="w-full min-h-[100px] h-[120px] max-h-[200px] text-dark-light-gray dark:text-light-light-gray text-fs-6 font-normal px-5 py-3 border border-light-jet dark:border-dark-jet rounded-lg bg-transparent outline-none focus:border-dark-blue-crayola dark:focus:border-light-blue-crayola mb-6 resize-y"
          />

          <button 
            type="submit"
            className="relative w-full md:w-max md:ml-auto bg-gradient-border-onyx text-dark-blue-crayola dark:text-light-blue-crayola flex justify-center items-center gap-2.5 px-5 py-3 rounded-lg text-fs-6 capitalize shadow-custom-3 z-[1] transition-all duration-300 hover:bg-gradient-blue-1 hover:before:bg-gradient-blue-2 disabled:opacity-70 disabled:cursor-not-allowed disabled:hover:bg-gradient-border-onyx disabled:hover:before:bg-gradient-jet
              before:content-[''] before:absolute before:inset-[1px] before:bg-gradient-jet before:rounded-[inherit] before:-z-[1]"
          >
            <IonIcon icon={paperPlane} className="text-base" />
            <span>Send Message</span>
          </button>
        </form>

        <div className="w-full h-px bg-light-jet dark:bg-dark-jet my-4" />

        <section className="mapbox relative h-[250px] w-full rounded-lg mb-8 border border-light-jet dark:border-dark-jet overflow-hidden">
          <figure className="h-full">
            <iframe
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=paris+(My%20Business%20Name)&amp;t=&amp;z=11&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              width="400" 
              height="300" 
              loading="lazy"
              className="w-full h-full border-none grayscale invert"
            />
          </figure>
        </section>
      </section>
    </div>
  );
}

export default Contact;
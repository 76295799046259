import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { IonIcon } from '@ionic/react';
import { chevronDown, eyeOutline } from 'ionicons/icons';

// Import project images
import cover1 from '../assets/images/cover-1.jpg';
import cover2 from '../assets/images/cover-2.jpg';
import cover3 from '../assets/images/cover-3.jpg';
import cover4 from '../assets/images/cover-4.jpg';
import cover5 from '../assets/images/cover-5.jpg';
import cover6 from '../assets/images/cover-6.jpg';
import cover7 from '../assets/images/cover-7.jpg';

// Create a mapping object for project images
const imageMap = {
  'cover-1.jpg': cover1,
  'cover-2.jpg': cover2,
  'cover-3.jpg': cover3,
  'cover-4.jpg': cover4,
  'cover-5.jpg': cover5,
  'cover-6.jpg': cover6,
  'cover-7.jpg': cover7
};

const Portfolio = ({ projectList }) => {
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Map the images in the project list
  const mappedProjects = projectList.map(project => ({
    ...project,
    pics: project.pics.map(pic => imageMap[pic])
  }));

  const [filteredProjects, setFilteredProjects] = useState(mappedProjects);

  // Extract unique categories
  const extractCategories = (projects) => {
    const categories = new Set();
    projects.forEach(project => {
      project.category.forEach(cat => categories.add(cat));
    });
    return ['All', ...Array.from(categories)];
  };

  const categoriesList = extractCategories(projectList);

  // Handle filter selection
  const handleFilter = (category) => {
    setSelectedCategory(category);
    setIsDropdownOpen(false);
    if (category === 'All') {
      setFilteredProjects(mappedProjects);
    } else {
      const filtered = mappedProjects.filter(project => project.category.includes(category));
      setFilteredProjects(filtered);
    }
  };

  return (
    <div>
      <header className="mb-8">
        <h2 className="text-fs-1 text-dark-light-gray dark:text-light-light-gray capitalize relative pb-2 after:content-[''] after:absolute after:bottom-0 after:left-0 after:w-[40px] after:h-[5px] after:bg-text-gradient-yellow after:rounded">
          Portfolio
        </h2>
      </header>

      <section>
        {/* Filter Dropdown */}
        <div className="relative mb-8">
          <div className="flex items-center gap-4">
            <span className="text-dark-light-gray dark:text-light-light-gray text-fs-6">
              Filter by tag:
            </span>
            <div className="relative flex-1 max-w-[300px]">
              <button 
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                className="relative bg-gradient-border-onyx text-dark-blue-crayola dark:text-light-blue-crayola
                  flex justify-between items-center w-full
                  py-2.5 px-4
                  border border-dark-jet dark:border-light-jet rounded-lg 
                  text-fs-6 font-light shadow-custom-3 z-[1] 
                  transition-all duration-300 hover:bg-gradient-blue-1
                  before:content-[''] before:absolute before:inset-[1px] before:bg-gradient-jet before:rounded-[inherit] before:-z-[1]"
              >
                <span>{selectedCategory}</span>
                <IonIcon 
                  icon={chevronDown} 
                  className={`transform transition-transform duration-300 ${isDropdownOpen ? 'rotate-180' : ''}`}
                />
              </button>

              {/* Dropdown Menu */}
              {isDropdownOpen && (
                <div className="absolute top-full left-0 w-full mt-2 
                  bg-dark-eerie-black-2/80 dark:bg-light-eerie-black-2/80 backdrop-blur-md
                  border border-dark-jet dark:border-light-jet rounded-lg 
                  shadow-custom-2 z-50 max-h-[200px] overflow-y-auto">
                  {categoriesList.map((category, index) => (
                    <button
                      key={index}
                      onClick={() => handleFilter(category)}
                      className={`w-full text-left p-4 text-fs-6
                        ${selectedCategory === category 
                          ? 'text-dark-blue-crayola dark:text-light-blue-crayola bg-dark-jet/20 dark:bg-light-jet/20' 
                          : 'text-dark-light-gray dark:text-light-light-gray hover:bg-dark-jet/10 dark:hover:bg-light-jet/10'
                        }
                        transition-colors duration-300
                        ${index !== categoriesList.length - 1 ? 'border-b border-dark-jet dark:border-light-jet' : ''}`}
                    >
                      {category}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Project Grid */}
        <ul className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
          {filteredProjects.map((project, index) => (
            <li key={index}>
              <Link to={`/project/${project.id}`} className="block w-full group">
                <figure className="relative w-full h-[200px] rounded-lg overflow-hidden mb-4">
                  <div className="absolute inset-0 bg-black/0 group-hover:bg-black/50 transition-all duration-300 z-10" />
                  
                  <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 
                    bg-dark-jet dark:bg-light-jet text-dark-blue-crayola dark:text-light-blue-crayola 
                    w-[45px] h-[45px] rounded-xl opacity-0 scale-80 
                    group-hover:opacity-100 group-hover:scale-100 
                    transition-all duration-300 z-20
                    flex items-center justify-center">
                    <IonIcon icon={eyeOutline} className="text-xl" />
                  </div>

                  <img 
                    src={project.pics[0]} 
                    alt={project.title} 
                    className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-110"
                  />
                </figure>

                <h3 className="text-fs-5 text-dark-light-gray dark:text-light-light-gray font-normal capitalize leading-tight mb-1 ml-2.5">
                  {project.title}
                </h3>
                <p className="text-dark-light-gray-70 dark:text-light-light-gray-70 text-fs-6 font-light ml-2.5">
                  {project.category.join(' | ')}
                </p>
              </Link>
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
}

export default Portfolio;